<template>
    <div class="mk-media-banner" @click.stop>
        <div
            v-if="magTransProperty(media, 'sensitive', 'isSensitive') && hide"
            class="sensitive"
            @click="hide = false"
        >
            <span class="icon"><i class="ph-warning ph-bold ph-lg"></i></span>
            <b>{{ i18n.ts.sensitive }}</b>
            <span>{{ i18n.ts.clickToShow }}</span>
        </div>
        <div
            v-else-if="
                ((type) => type.startsWith('audio') && type !== 'audio/midi')(
                    magTransProperty(media, 'mime_type', 'type')
                )
            "
            class="audio"
        >
            <fieldset class="audio-player-frame">
                <legend>{{ media.name }}</legend>
                <MagVideoPlayer :video="media"></MagVideoPlayer>
            </fieldset>
        </div>
        <a
            v-else
            class="download"
            :href="media.url"
            :title="media.name"
            :download="media.name"
        >
            <span class="icon"
                ><i class="ph-download-simple ph-bold ph-lg"></i
            ></span>
            <b>{{ media.name }}</b>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import type * as misskey from "calckey-js";
import { i18n } from "@/i18n";
import { packed } from "magnetar-common";
import { magTransProperty } from "@/scripts-mag/mag-util";
import MagVideoPlayer from "@/components/MagVideoPlayer.vue";

const props = withDefaults(
    defineProps<{
        media: packed.PackDriveFileBase | misskey.entities.DriveFile;
    }>(),
    {}
);

let hide = ref(true);
</script>

<style lang="scss" scoped>
.mk-media-banner {
    width: 100%;
    border-radius: 4px;
    margin-top: 4px;

    > .download,
    > .sensitive {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 8px 12px;
        white-space: nowrap;

        > * {
            display: block;
        }

        > b {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        > *:not(:last-child) {
            margin-right: 0.2em;
        }

        > .icon {
            font-size: 1.6em;
        }
    }

    > .download {
        background: var(--noteAttachedFile);
    }

    > .sensitive {
        background: #111;
        color: #fff;
    }

    > .audio {
        & fieldset.audio-player-frame {
            border-color: var(--accentedBg);
            border-radius: 10px;
            border-style: solid;

            & legend {
                padding-inline: 5px;
                opacity: 0.7;
                font-style: italic;
                font-size: 0.9em;
            }
        }

        .audio {
            display: block;
            width: 100%;
        }
    }
}
</style>
