<template>
    <MkModal
        ref="modal"
        :prefer-type="'dialog'"
        @click="modal.close()"
        @closed="onModalClosed()"
    >
        <MkPostForm
            ref="form"
            style="margin: 0 auto auto auto"
            v-bind="props"
            autofocus
            freeze-after-posted
            @posted="onPosted"
            @cancel="modal.close()"
            @esc="modal.close()"
        />
    </MkModal>
</template>

<script lang="ts" setup>
import { shallowRef } from "vue";

import * as misskey from "calckey-js";
import MkModal from "@/components/MkModal.vue";
import MkPostForm from "@/components/MkPostForm.vue";
import { packed } from "magnetar-common";

const props = defineProps<{
    reply?: packed.PackNoteMaybeFull;
    renote?: packed.PackNoteMaybeFull;
    mention?: misskey.entities.User;
    specified?: misskey.entities.User;
    initialText?: string;
    initialVisibility?: typeof misskey.noteVisibilities;
    initialFiles?: misskey.entities.DriveFile[];
    initialLocalOnly?: boolean;
    initialVisibleUsers?: misskey.entities.User[];
    initialNote?: packed.PackNoteMaybeFull;
    instant?: boolean;
    fixed?: boolean;
    autofocus?: boolean;
    editId?: misskey.entities.Note["id"];
}>();

const emit = defineEmits<{
    (ev: "closed"): void;
}>();

let modal = shallowRef<InstanceType<typeof MkModal>>();
let form = shallowRef<InstanceType<typeof MkPostForm>>();

function onPosted() {
    modal.value.close({
        useSendAnimation: true,
    });
}

function onModalClosed() {
    emit("closed");
}
</script>
