<template>
    <div
        v-if="hide"
        class="icozogqfvdetwohsdglrbswgrejoxbdj"
        @click="hide = false"
    >
        <div>
            <b
                ><i class="ph-warning ph-bold ph-lg"></i>
                {{ i18n.ts.sensitive }}</b
            >
            <span>{{ i18n.ts.clickToShow }}</span>
        </div>
    </div>
    <div v-else class="video">
        <MagVideoPlayer :video="video"> </MagVideoPlayer>
        <button
            v-tooltip="i18n.ts.hide"
            class="_button hide"
            @click="hide = true"
        >
            <i class="ph-eye-slash ph-bold ph-lg"></i>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import type * as misskey from "calckey-js";
import { defaultStore } from "@/store";
import { i18n } from "@/i18n";
import { packed } from "magnetar-common";
import { magTransProperty } from "@/scripts-mag/mag-util";
import MagVideoPlayer from "./MagVideoPlayer.vue";

const props = defineProps<{
    video: packed.PackDriveFileBase | misskey.entities.DriveFile;
}>();

const hide = ref(
    defaultStore.state.nsfw === "force"
        ? true
        : magTransProperty(props.video, "sensitive", "isSensitive") &&
              defaultStore.state.nsfw !== "ignore"
);
</script>

<style lang="scss" scoped>
.video {
    position: relative;

    > .hide {
        display: block;
        position: absolute;
        border-radius: 6px;
        background-color: var(--accentedBg);
        -webkit-backdrop-filter: var(--blur, blur(15px));
        backdrop-filter: var(--blur, blur(15px));
        color: var(--accent);
        font-size: 0.8em;
        padding: 6px 8px;
        text-align: center;
        top: 12px;
        right: 12px;

        > i {
            display: block;
        }
    }
}

.icozogqfvdetwohsdglrbswgrejoxbdj {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111;
    color: #fff;

    > div {
        display: table-cell;
        text-align: center;
        font-size: 12px;

        > b {
            display: block;
        }
    }
}
</style>
