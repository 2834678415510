<template>
    <span
        v-if="note.visibility !== 'public' && note.visibility !== 'Public'"
        :class="$style.visibility"
    >
        <i
            v-if="note.visibility === 'home' || note.visibility === 'Home'"
            class="ph-house ph-bold ph-lg"
            v-tooltip="i18n.ts._visibility.home"
        ></i>
        <i
            v-else-if="
                note.visibility === 'followers' ||
                note.visibility === 'Followers'
            "
            class="ph-lock ph-bold ph-lg"
            v-tooltip="i18n.ts._visibility.followers"
        ></i>
        <i
            v-else-if="
                note.visibility === 'specified' || note.visibility === 'Direct'
            "
            ref="specified"
            class="ph-envelope-simple-open ph-bold ph-lg"
        ></i>
    </span>
    <span
        v-if="magTransProperty(note, 'localOnly', 'local_only')"
        :class="$style.localOnly"
        ><i
            class="ph-hand-fist ph-bold ph-lg"
            v-tooltip="i18n.ts._visibility.localOnly"
        ></i
    ></span>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import XDetails from "@/components/MkUsersTooltip.vue";
import * as os from "@/os";
import { useTooltip } from "@/scripts/use-tooltip";
import { i18n } from "@/i18n";
import * as Misskey from "calckey-js";
import { packed } from "magnetar-common";
import { magTransProperty } from "@/scripts-mag/mag-util";

const props = defineProps<{
    note:
        | Pick<
              Misskey.entities.Note,
              "visibility" | "localOnly" | "visibleUserIds"
          >
        | Pick<
              packed.PackNoteBase,
              "visibility" | "local_only" | "visible_user_ids"
          >;
}>();

const specified = ref<HTMLElement>();

if (props.note.visibility === "specified") {
    useTooltip(specified, async (showing) => {
        const users = await os.api("users/show", {
            userIds:
                magTransProperty(
                    props.note,
                    "visibleUserIds",
                    "visible_user_ids",
                ) ?? [],
            limit: 10,
        });

        os.popup(
            XDetails,
            {
                showing,
                users,
                count:
                    magTransProperty(
                        props.note,
                        "visibleUserIds",
                        "visible_user_ids",
                    )?.length ?? 0,
                targetElement: specified.value,
            },
            {},
            "closed",
        );
    });
}
</script>

<style lang="scss" module>
.visibility,
.localOnly {
    margin-left: 0.5em;
}
</style>
