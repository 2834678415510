import * as Misskey from "calckey-js";
import { markRaw } from "vue";
import { $i } from "@/account";
import { magStreamingUrl, url } from "@/config";
import { MagEventChannel } from "magnetar-common";

export const stream = markRaw(
    new Misskey.Stream(
        url,
        $i
            ? {
                  token: $i.token,
              }
            : null,
    ),
);

window.setTimeout(heartbeat, 1000 * 60);

function heartbeat(): void {
    if (stream != null && document.visibilityState === "visible") {
        stream.send("ping");
    }
    window.setTimeout(heartbeat, 1000 * 60);
}

export const magStream = markRaw(
    new MagEventChannel(magStreamingUrl, $i ? $i.token : null),
);
