<template>
    <div class="tivcixzd" :class="{ done: closed || isVoted }">
        <ul>
            <li
                v-for="(choice, i) in note.poll.options"
                :key="i"
                :class="{ voted: choice.voted }"
                @click.stop="vote(i)"
            >
                <div
                    class="backdrop"
                    :style="{
                        width: `${
                            showResult ? (choice.votes_count / total) * 100 : 0
                        }%`,
                    }"
                ></div>
                <span>
                    <template v-if="choice.voted"
                        ><i class="ph-check ph-bold ph-lg"></i
                    ></template>
                    <Mfm
                        :text="choice.title"
                        :plain="true"
                        :custom-emojis="note.emojis"
                    />
                    <span v-if="showResult" class="votes"
                        >({{
                            i18n.t("_poll.votesCount", {
                                n: choice.votes_count,
                            })
                        }})</span
                    >
                </span>
            </li>
        </ul>
        <p v-if="!readOnly">
            <span>{{ i18n.t("_poll.totalVotes", { n: total }) }}</span>
            <span v-if="!closed && !isVoted">
                <span> · </span>
                <a @click.stop="showResult = !showResult">{{
                    showResult ? i18n.ts._poll.vote : i18n.ts._poll.showResult
                }}</a>
            </span>
            <span v-if="!isLocal">
                <span> · </span>
                <a v-if="!pollRefreshing" @click.stop="refresh">{{
                    i18n.ts.reload
                }}</a>
                <MagSpinner v-else />
            </span>
            <span v-if="isVoted"> · {{ i18n.ts._poll.voted }}</span>
            <span v-else-if="closed"> · {{ i18n.ts._poll.closed }}</span>
            <span v-if="remaining > 0"> · {{ timer }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRaw } from "vue";
import { sum } from "@/scripts/array";
import { pleaseLogin } from "@/scripts/please-login";
import * as os from "@/os";
import { i18n } from "@/i18n";
import { useInterval } from "@/scripts/use-interval";
import { endpoints, packed } from "magnetar-common";

const props = defineProps<{
    note: packed.PackNoteMaybeFull & { poll: {} };
    readOnly?: boolean;
}>();

const pollRefreshing = ref(false);
const remaining = ref(-1);

const total = computed(() =>
    sum(props.note.poll.options.map((x) => x.votes_count))
);
const closed = computed(() => remaining.value === 0);
const isLocal = computed(() => !props.note.uri);
const isVoted = computed(
    () =>
        !props.note.poll.multiple_choice &&
        props.note.poll.options.some((c) => c.voted ?? false)
);
const timer = computed(() =>
    i18n.t(
        remaining.value >= 86400
            ? "_poll.remainingDays"
            : remaining.value >= 3600
            ? "_poll.remainingHours"
            : remaining.value >= 60
            ? "_poll.remainingMinutes"
            : "_poll.remainingSeconds",
        {
            s: Math.floor(remaining.value % 60),
            m: Math.floor(remaining.value / 60) % 60,
            h: Math.floor(remaining.value / 3600) % 24,
            d: Math.floor(remaining.value / 86400),
        }
    )
);

const showResult = ref(props.readOnly || isVoted.value);

// 期限付きアンケート
if (props.note.poll.expires_at) {
    const tick = () => {
        remaining.value = Math.floor(
            Math.max(
                new Date(props.note.poll.expires_at!).getTime() - Date.now(),
                0
            ) / 1000
        );
        if (remaining.value === 0) {
            showResult.value = true;
        }
    };

    useInterval(tick, 3000, {
        immediate: true,
        afterMounted: false,
    });
}

async function refresh() {
    if (!props.note.uri) return;

    pollRefreshing.value = true;

    os.api("ap/show", { uri: props.note.uri })
        .then((obj) => {
            if (obj && obj.type === "Note") {
                os.magApi(
                    endpoints.GetNoteById,
                    { attachments: true },
                    { id: obj.object.id }
                ).then((n) => {
                    props.note.poll = { ...toRaw(props.note.poll), ...n.poll };
                });
            }
        })
        .catch((err) => {
            os.alert({
                type: "error",
                text: err.message + "\n" + (err as any).id,
            });
        })
        .finally(() => {
            pollRefreshing.value = false;
        });
}

const vote = async (id: number) => {
    pleaseLogin();

    if (props.readOnly || closed.value || isVoted.value) return;

    const { canceled } = await os.confirm({
        type: "question",
        text: i18n.t("voteConfirm", {
            choice: props.note.poll.options[id].title,
        }),
    });
    if (canceled) return;

    await os.api("notes/polls/vote", {
        noteId: props.note.id,
        choice: id,
    });
    if (!showResult.value) showResult.value = !props.note.poll.multiple_choice;
};
</script>

<style lang="scss" scoped>
.tivcixzd {
    > ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            display: block;
            position: relative;
            margin: 4px 0;
            padding: 4px;
            //border: solid 0.5px var(--divider);
            background: var(--accentedBg);
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            > .backdrop {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background: var(--accent);
                background: linear-gradient(
                    90deg,
                    var(--buttonGradateA),
                    var(--buttonGradateB)
                );
                transition: width 1s ease;
            }

            > span {
                position: relative;
                display: inline-block;
                padding: 3px 5px;
                background: var(--panel);
                border-radius: 3px;

                > i {
                    margin-right: 4px;
                    color: var(--accent);
                }

                > .votes {
                    margin-left: 4px;
                    opacity: 0.7;
                }
            }
        }
    }

    > p {
        color: var(--fg);

        a {
            color: inherit;
        }
    }

    &.done {
        > ul > li {
            cursor: default;
        }
    }
}
</style>
