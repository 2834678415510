"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    isAPIError: function() {
        return isAPIError;
    },
    APIClient: function() {
        return APIClient;
    }
});
const MK_API_ERROR = Symbol();
function isAPIError(reason) {
    return reason[MK_API_ERROR] === true;
}
class APIClient {
    origin;
    credential;
    fetch;
    constructor(opts){
        this.origin = opts.origin;
        this.credential = opts.credential;
        // ネイティブ関数をそのまま変数に代入して使おうとするとChromiumではIllegal invocationエラーが発生するため、
        // 環境で実装されているfetchを使う場合は無名関数でラップして使用する
        this.fetch = opts.fetch || ((...args)=>fetch(...args));
    }
    request(endpoint, params = {}, credential) {
        const promise = new Promise((resolve, reject)=>{
            this.fetch(`${this.origin}/api/${endpoint}`, {
                method: "POST",
                body: JSON.stringify({
                    ...params,
                    i: credential !== undefined ? credential : this.credential
                }),
                credentials: "omit",
                cache: "no-cache"
            }).then(async (res)=>{
                const body = res.status === 204 ? null : await res.json();
                if (res.status === 200) {
                    resolve(body);
                } else if (res.status === 204) {
                    resolve(null);
                } else {
                    reject({
                        [MK_API_ERROR]: true,
                        ...body.error
                    });
                }
            }).catch(reject);
        });
        return promise;
    }
}
