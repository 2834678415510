"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    notificationTypes: function() {
        return notificationTypes;
    },
    noteVisibilities: function() {
        return noteVisibilities;
    },
    mutedNoteReasons: function() {
        return mutedNoteReasons;
    },
    ffVisibility: function() {
        return ffVisibility;
    },
    permissions: function() {
        return permissions;
    }
});
const notificationTypes = [
    "follow",
    "mention",
    "reply",
    "renote",
    "quote",
    "reaction",
    "pollVote",
    "pollEnded",
    "receiveFollowRequest",
    "followRequestAccepted",
    "app"
];
const noteVisibilities = [
    "public",
    "home",
    "followers",
    "specified"
];
const mutedNoteReasons = [
    "word",
    "manual",
    "spam",
    "other"
];
const ffVisibility = [
    "public",
    "followers",
    "private"
];
const permissions = [
    "read:account",
    "write:account",
    "read:blocks",
    "write:blocks",
    "read:drive",
    "write:drive",
    "read:favorites",
    "write:favorites",
    "read:following",
    "write:following",
    "read:messaging",
    "write:messaging",
    "read:mutes",
    "write:mutes",
    "write:notes",
    "read:notifications",
    "write:notifications",
    "read:reactions",
    "write:reactions",
    "write:votes"
];
