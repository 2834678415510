<template>
    <section
        v-size="{ max: [310, 500] }"
        class="gafaadew"
        :class="{ modal, _popup: modal }"
        :aria-label="i18n.ts._pages.blocks.post"
        @dragover.stop="onDragover"
        @dragenter="onDragenter"
        @dragleave="onDragleave"
        @drop.stop="onDrop"
    >
        <header>
            <div class="left">
                <button
                    v-if="!fixed"
                    class="cancel _button"
                    :aria-label="i18n.ts.close"
                    @click="cancel"
                >
                    <i class="ph-x ph-bold ph-lg"></i>
                </button>
                <button
                    v-if="$props.editId == null"
                    v-tooltip="i18n.ts.switchAccount"
                    class="account _button"
                    @click="openAccountMenu"
                >
                    <MagAvatarResolvingProxy
                        :user="postAccount ?? $i"
                        class="avatar"
                    />
                </button>
                <bdi class="form-username">
                    <MkUserName
                        class="form-username-text"
                        :user="postAccount ?? $i"
                    ></MkUserName>
                </bdi>
            </div>
            <div class="right">
                <span
                    class="text-count"
                    v-tooltip="i18n.ts.characterLimit"
                    :class="{ over: textLength > maxTextLength }"
                    >{{ maxTextLength - textLength }}</span
                >
                <span
                    v-if="localOnly"
                    v-tooltip="i18n.ts.localOnly"
                    class="local-only"
                    ><i class="ph-hand-fist ph-bold ph-lg"></i
                ></span>
                <button
                    ref="visibilityButton"
                    v-tooltip="i18n.ts.visibility"
                    class="_button visibility"
                    @click="setVisibility"
                >
                    <span v-if="visibility === 'public'"
                        ><i class="ph-planet ph-bold ph-lg"></i
                    ></span>
                    <span v-if="visibility === 'home'"
                        ><i class="ph-house ph-bold ph-lg"></i
                    ></span>
                    <span v-if="visibility === 'followers'"
                        ><i class="ph-lock ph-bold ph-lg"></i
                    ></span>
                    <span v-if="visibility === 'specified'"
                        ><i class="ph-envelope-simple-open ph-bold ph-lg"></i
                    ></span>
                </button>
                <button
                    v-tooltip="i18n.ts.previewNoteText"
                    class="_button preview"
                    :class="{ active: showPreview }"
                    @click="showPreview = !showPreview"
                >
                    <i class="ph-binoculars ph-bold ph-lg"></i>
                </button>
                <button
                    class="submit _buttonGradate"
                    :disabled="!canPost"
                    data-cy-open-post-form-submit
                    @click="post"
                >
                    {{ submitText
                    }}<i
                        :class="
                            reply
                                ? 'ph-arrow-u-up-left ph-bold ph-lg'
                                : renote
                                ? 'ph-quotes ph-bold ph-lg'
                                : 'ph-paper-plane-tilt ph-bold ph-lg'
                        "
                    ></i>
                </button>
            </div>
        </header>
        <div class="form" :class="{ fixed }">
            <XNoteSimple v-if="reply" class="preview" :note="reply" />
            <XNoteSimple v-if="renote" class="preview" :note="renote" />
            <div v-if="quoteId" class="with-quote">
                <i class="ph-quotes ph-bold ph-lg"></i>
                {{ i18n.ts.quoteAttached }}
                <button class="_button" @click="quoteId = null">
                    <i class="ph-x ph-bold ph-lg"></i>
                </button>
            </div>
            <div v-if="visibility === 'specified'" class="to-specified">
                <span style="margin-right: 8px">{{ i18n.ts.recipient }}</span>
                <div class="visibleUsers">
                    <span v-for="u in visibleUsers" :key="u.id">
                        <MkAcct :user="u" />
                        <button class="_button" @click="removeVisibleUser(u)">
                            <i class="ph-x ph-bold ph-lg"></i>
                        </button>
                    </span>
                    <button class="_button" @click="addVisibleUser">
                        <i class="ph-plus ph-bold ph-md ph-fw ph-lg"></i>
                    </button>
                </div>
            </div>
            <MkInfo
                v-if="hasNotSpecifiedMentions"
                warn
                class="hasNotSpecifiedMentions"
                >{{ i18n.ts.notSpecifiedMentionWarning }} -
                <button class="_textButton" @click="addMissingMention()">
                    {{ i18n.ts.add }}
                </button>
            </MkInfo>
            <input
                v-show="useCw"
                ref="cwInputEl"
                v-model="cw"
                class="cw"
                :placeholder="i18n.ts.annotation"
                @keydown="onKeydown"
            />
            <textarea
                ref="textareaEl"
                v-model="text"
                class="text"
                :class="{ withCw: useCw }"
                :disabled="posting"
                :placeholder="placeholder"
                data-cy-post-form-text
                @keydown="onKeydown"
                @paste="onPaste"
                @compositionupdate="onCompositionUpdate"
                @compositionend="onCompositionEnd"
            />
            <input
                v-show="withHashtags"
                ref="hashtagsInputEl"
                v-model="hashtags"
                class="hashtags"
                :placeholder="i18n.ts.hashtags"
                list="hashtags"
            />
            <XPostFormAttaches
                class="attaches"
                :files="files"
                @updated="updateFiles"
                @detach="detachFile"
                @changeSensitive="updateFileSensitive"
                @changeName="updateFileName"
            />
            <XPollEditor v-if="poll" v-model="poll" @destroyed="poll = null" />
            <XNotePreview v-if="showPreview" class="preview" :text="text" />
            <footer>
                <button
                    v-tooltip="i18n.ts.attachFile"
                    class="_button"
                    @click="chooseFileFrom"
                >
                    <i class="ph-upload ph-bold ph-lg"></i>
                </button>
                <button
                    v-tooltip="i18n.ts.poll"
                    class="_button"
                    :class="{ active: poll }"
                    @click="togglePoll"
                >
                    <i class="ph-microphone-stage ph-bold ph-lg"></i>
                </button>
                <button
                    v-tooltip="i18n.ts.useCw"
                    class="_button"
                    :class="{ active: useCw }"
                    @click="useCw = !useCw"
                >
                    <i class="ph-eye-slash ph-bold ph-lg"></i>
                </button>
                <button
                    v-tooltip="i18n.ts.mention"
                    class="_button"
                    @click="insertMention"
                >
                    <i class="ph-at ph-bold ph-lg"></i>
                </button>
                <button
                    v-tooltip="i18n.ts.hashtags"
                    class="_button"
                    :class="{ active: withHashtags }"
                    @click="withHashtags = !withHashtags"
                >
                    <i class="ph-hash ph-bold ph-lg"></i>
                </button>
                <button
                    v-tooltip="i18n.ts.emoji"
                    class="_button"
                    @click="insertEmoji"
                >
                    <i class="ph-smiley ph-bold ph-lg"></i>
                </button>
                <!--	v-if="showMfmCheatsheet" -->
                <button
                    v-tooltip="i18n.ts._mfm.cheatSheet"
                    class="_button right"
                    @click="openCheatSheet"
                >
                    <i class="ph-question ph-bold ph-lg"></i>
                </button>
            </footer>
            <datalist id="hashtags">
                <option
                    v-for="hashtag in recentHashtags"
                    :key="hashtag"
                    :value="hashtag"
                />
            </datalist>
        </div>
    </section>
</template>

<script lang="ts" setup>
import {
    computed,
    defineAsyncComponent,
    inject,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from "vue";
import * as mfm from "mfm-js";
import * as misskey from "calckey-js";
import insertTextAtCursor from "insert-text-at-cursor";
import { length } from "stringz";
import { toASCII } from "punycode/";
import * as Acct from "calckey-js/built/acct";
import XNoteSimple from "@/components/MagNoteSimple.vue";
import XNotePreview from "@/components/MkNotePreview.vue";
import XPostFormAttaches from "@/components/MkPostFormAttaches.vue";
import XPollEditor from "@/components/MkPollEditor.vue";
import { host, url } from "@/config";
import { erase, unique } from "@/scripts/array";
import { extractMentions } from "@/scripts/extract-mentions";
import { formatTimeString } from "@/scripts/format-time-string";
import { Autocomplete } from "@/scripts/autocomplete";
import * as os from "@/os";
import { selectFiles } from "@/scripts/select-file";
import { defaultStore } from "@/store";
import MkInfo from "@/components/MkInfo.vue";
import { i18n } from "@/i18n";
import { instance } from "@/instance";
import {
    $i,
    getAccounts,
    openAccountMenu as openAccountMenu_,
} from "@/account";
import { uploadFile } from "@/scripts/upload";
import XCheatSheet from "@/components/MkCheatSheetDialog.vue";
import { preprocess } from "@/scripts/preprocess";
import { packed } from "magnetar-common";
import { magLegacyVisibility } from "@/scripts-mag/mag-util";

const modal = inject("modal");

const props = withDefaults(
    defineProps<{
        reply?: packed.PackNoteMaybeFull;
        renote?: packed.PackNoteMaybeFull;
        mention?: misskey.entities.User;
        specified?: misskey.entities.User;
        initialText?: string;
        initialVisibility?: (typeof misskey.noteVisibilities)[number];
        initialFiles?: (
            | packed.PackDriveFileBase
            | misskey.entities.DriveFile
        )[];
        initialLocalOnly?: boolean;
        initialVisibleUsers?: misskey.entities.User[];
        initialNote?: packed.PackNoteMaybeFull;
        instant?: boolean;
        fixed?: boolean;
        autofocus?: boolean;
        showMfmCheatSheet?: boolean;
        editId?: misskey.entities.Note["id"];
    }>(),
    {
        initialVisibleUsers: () => [],
        autofocus: true,
        showMfmCheatSheet: true,
    }
);

const emit = defineEmits<{
    (ev: "posted"): void;
    (ev: "cancel"): void;
    (ev: "esc"): void;
}>();

const textareaEl = ref<HTMLTextAreaElement | null>(null);
const cwInputEl = ref<HTMLInputElement | null>(null);
const hashtagsInputEl = ref<HTMLInputElement | null>(null);
const visibilityButton = ref<HTMLElement | null>(null);

let posting = ref(false);
let text = ref(props.initialText ?? "");
let files = ref(props.initialFiles ?? []);
let poll = ref<{
    choices: string[];
    multiple: boolean;
    expiresAt: string | null;
    expiredAfter: string | null;
} | null>(null);
let useCw = ref(false);
let showPreview = ref(false);
let cw = ref<string | null>(null);
let localOnly = ref<boolean>(
    props.initialLocalOnly ?? defaultStore.state.rememberNoteVisibility
        ? defaultStore.state.localOnly
        : defaultStore.state.defaultNoteLocalOnly
);
let visibility = ref(
    props.initialVisibility ??
        ((defaultStore.state.rememberNoteVisibility
            ? defaultStore.state.visibility
            : defaultStore.state
                  .defaultNoteVisibility) as (typeof misskey.noteVisibilities)[number])
);
let visibleUsers = ref([]);
if (props.initialVisibleUsers) {
    props.initialVisibleUsers.forEach(pushVisibleUser);
}
let autocomplete = ref<Autocomplete[] | null>(null);
let draghover = ref(false);
let quoteId = ref<string | null>(null);
let hasNotSpecifiedMentions = ref(false);
let recentHashtags = ref(JSON.parse(localStorage.getItem("hashtags") || "[]"));
let imeText = ref("");

const draftKey = computed((): string => {
    if (props.editId) {
        return `edit:${props.editId}`;
    }

    let key = "";

    if (props.renote) {
        key += `renote:${props.renote.id}`;
    } else if (props.reply) {
        key += `reply:${props.reply.id}`;
    } else {
        key += "note";
    }

    return key;
});

const placeholder = computed((): string => {
    if (props.renote) {
        return i18n.ts._postForm.quotePlaceholder;
    } else if (props.reply) {
        return i18n.ts._postForm.replyPlaceholder;
    } else {
        const xs = [
            i18n.ts._postForm._placeholders.a,
            i18n.ts._postForm._placeholders.b,
            i18n.ts._postForm._placeholders.c,
            i18n.ts._postForm._placeholders.d,
            i18n.ts._postForm._placeholders.e,
            i18n.ts._postForm._placeholders.f,
        ];
        return xs[Math.floor(Math.random() * xs.length)];
    }
});

const submitText = computed((): string => {
    return props.editId
        ? i18n.ts.edit
        : props.renote
        ? i18n.ts.quote
        : props.reply
        ? i18n.ts.reply
        : i18n.ts.note;
});

const textLength = computed((): number => {
    return length((preprocess(text.value) + imeText.value).trim());
});

const maxTextLength = computed((): number => {
    return instance ? instance.maxNoteTextLength : 1000;
});

const canPost = computed((): boolean => {
    return (
        !posting.value &&
        (1 <= textLength.value ||
            1 <= files.value.length ||
            !!poll.value ||
            !!props.renote) &&
        textLength.value <= maxTextLength.value &&
        (!poll.value || poll.value.choices.length >= 2)
    );
});

const withHashtags = computed(
    defaultStore.makeGetterSetter("postFormWithHashtags")
);
const hashtags = computed<string>(
    defaultStore.makeGetterSetter("postFormHashtags")
);

watch(text, () => {
    checkMissingMention();
});

watch(
    visibleUsers,
    () => {
        checkMissingMention();
    },
    {
        deep: true,
    }
);

if (props.mention) {
    text.value = props.mention.host
        ? `@${props.mention.username}@${toASCII(props.mention.host)}`
        : `@${props.mention.username}`;
    text.value += " ";
}

if (
    props.reply &&
    (props.reply.user.username !== $i.username ||
        (props.reply.user.host != null && props.reply.user.host !== host))
) {
    text.value = `@${props.reply.user.username}${
        props.reply.user.host != null
            ? "@" + toASCII(props.reply.user.host)
            : ""
    } `;
}

if (props.reply && props.reply.text != null) {
    const ast = mfm.parse(props.reply.text);
    const otherHost = props.reply.user.host;

    for (const x of extractMentions(ast)) {
        const mention = x.host
            ? `@${x.username}@${toASCII(x.host)}`
            : otherHost == null || otherHost === host
            ? `@${x.username}`
            : `@${x.username}@${toASCII(otherHost)}`;

        // 自分は除外
        if ($i.username === x.username && (x.host == null || x.host === host))
            continue;

        // 重複は除外
        if (text.value.includes(`${mention} `)) continue;

        text.value += `${mention} `;
    }
}

// 公開以外へのリプライ時は元の公開範囲を引き継ぐ
if (
    props.reply &&
    ["home", "followers", "specified"].includes(
        magLegacyVisibility(props.reply.visibility)
    )
) {
    if (
        magLegacyVisibility(props.reply.visibility) === "home" &&
        visibility.value === "followers"
    ) {
        visibility.value = "followers";
    } else if (
        ["home", "followers"].includes(
            magLegacyVisibility(props.reply.visibility)
        ) &&
        visibility.value === "specified"
    ) {
        visibility.value = "specified";
    } else {
        visibility.value = magLegacyVisibility(props.reply.visibility);
    }
    if (visibility.value === "specified") {
        const ids = props.reply.visible_user_ids;
        if (ids) {
            os.api("users/show", {
                userIds: ids.filter(
                    (uid) => uid !== $i.id && uid !== props.reply!.user.id
                ),
            }).then((users) => {
                users.forEach(pushVisibleUser);
            });
        }

        if (props.reply.user.id !== $i.id) {
            os.api("users/show", { userId: props.reply.user.id }).then(
                (user) => {
                    pushVisibleUser(user);
                }
            );
        }
    }
}

if (props.specified) {
    visibility.value = "specified";
    pushVisibleUser(props.specified);
}

// keep cw when reply
if (defaultStore.state.keepCw && props.reply && props.reply.cw) {
    useCw.value = true;
    cw.value = props.reply.cw;
}

function watchForDraft() {
    watch(text, () => saveDraft());
    watch(useCw, () => saveDraft());
    watch(cw, () => saveDraft());
    watch(poll, () => saveDraft());
    watch(files, () => saveDraft(), { deep: true });
    watch(visibility, () => saveDraft());
    watch(localOnly, () => saveDraft());
}

function checkMissingMention() {
    if (visibility.value === "specified") {
        const ast = mfm.parse(text.value);

        for (const x of extractMentions(ast)) {
            if (
                !visibleUsers.value.some(
                    (u) => u.username === x.username && u.host === x.host
                )
            ) {
                hasNotSpecifiedMentions.value = true;
                return;
            }
        }
        hasNotSpecifiedMentions.value = false;
    }
}

function addMissingMention() {
    const ast = mfm.parse(text.value);

    for (const x of extractMentions(ast)) {
        if (
            !visibleUsers.value.some(
                (u) => u.username === x.username && u.host === x.host
            )
        ) {
            os.api("users/show", { username: x.username, host: x.host }).then(
                (user) => {
                    visibleUsers.value.push(user);
                }
            );
        }
    }
}

function togglePoll() {
    if (poll.value) {
        poll.value = null;
    } else {
        poll.value = {
            choices: ["", ""],
            multiple: false,
            expiresAt: null,
            expiredAfter: null,
        };
    }
}

function addTag(tag: string) {
    insertTextAtCursor(textareaEl.value, ` #${tag} `);
}

function focus() {
    if (textareaEl.value) {
        textareaEl.value.focus();
        textareaEl.value.setSelectionRange(
            textareaEl.value.value.length,
            textareaEl.value.value.length
        );
    }
}

function chooseFileFrom(ev) {
    selectFiles(ev.currentTarget ?? ev.target, i18n.ts.attachFile).then(
        (files_) => {
            for (const file of files_) {
                files.value.push(file);
            }
        }
    );
}

function detachFile(id) {
    files.value = files.value.filter((x) => x.id !== id);
}

function updateFiles(_files) {
    files.value = _files;
}

function updateFileSensitive(file, sensitive) {
    const idx = files.value.findIndex((x) => x.id === file.id);
    const f = files.value[idx];
    if ("isSensitive" in f) f.isSensitive = sensitive;
    else f.sensitive = sensitive;
}

function updateFileName(file, name) {
    files.value[files.value.findIndex((x) => x.id === file.id)].name = name;
}

function upload(file: File, name?: string) {
    uploadFile(file, defaultStore.state.uploadFolder, name).then((res) => {
        files.value.push(res);
    });
}

function setVisibility() {
    os.popup(
        defineAsyncComponent(
            () => import("@/components/MkVisibilityPicker.vue")
        ),
        {
            currentVisibility: visibility.value,
            currentLocalOnly: localOnly.value,
            src: visibilityButton.value,
        },
        {
            changeVisibility: (v) => {
                visibility.value = v;
                if (defaultStore.state.rememberNoteVisibility) {
                    defaultStore.set("visibility", visibility.value);
                }
            },
            changeLocalOnly: (v) => {
                localOnly.value = v;
                if (defaultStore.state.rememberNoteVisibility) {
                    defaultStore.set("localOnly", localOnly.value);
                }
            },
        },
        "closed"
    );
}

function pushVisibleUser(user) {
    if (
        !visibleUsers.value.some(
            (u) => u.username === user.username && u.host === user.host
        )
    ) {
        visibleUsers.value.push(user);
    }
}

function addVisibleUser() {
    os.selectUser().then((user) => {
        pushVisibleUser(user);
    });
}

function removeVisibleUser(user) {
    visibleUsers.value = erase(user, visibleUsers.value);
}

function clear() {
    text.value = "";
    files.value = [];
    poll.value = null;
    quoteId.value = null;
}

function onKeydown(ev: KeyboardEvent) {
    if (ev.key === "Enter" && (ev.ctrlKey || ev.metaKey) && canPost.value)
        post();
    if (ev.key === "Escape") emit("esc");
}

function onCompositionUpdate(ev: CompositionEvent) {
    imeText.value = ev.data;
}

function onCompositionEnd(ev: CompositionEvent) {
    imeText.value = "";
}

async function onPaste(ev: ClipboardEvent) {
    if (!ev.clipboardData) {
        return;
    }

    for (const { item, i } of Array.from(ev.clipboardData.items).map(
        (item, i) => ({ item, i })
    )) {
        if (item.kind === "file") {
            const file = item.getAsFile();
            if (!file) {
                continue;
            }

            const lio = file.name.lastIndexOf(".");
            const ext = lio >= 0 ? file.name.slice(lio) : "";
            const formatted = `${formatTimeString(
                new Date(file.lastModified),
                defaultStore.state.pastedFileName
            ).replace(/{{number}}/g, `${i + 1}`)}${ext}`;
            upload(file, formatted);
        }
    }

    const paste = ev.clipboardData.getData("text");

    if (!props.renote && !quoteId.value && paste.startsWith(url + "/notes/")) {
        ev.preventDefault();

        os.yesno({
            type: "info",
            text: i18n.ts.quoteQuestion,
        }).then(({ canceled }) => {
            if (canceled) {
                insertTextAtCursor(textareaEl.value, paste);
                return;
            }

            quoteId.value =
                paste.substring(url.length).match(/^\/notes\/(.+?)\/?$/)?.[1] ??
                null;
        });
    }
}

function onDragover(ev) {
    if (!ev.dataTransfer.items[0]) return;
    const isFile = ev.dataTransfer.items[0].kind === "file";
    const isDriveFile = ev.dataTransfer.types[0] === _DATA_TRANSFER_DRIVE_FILE_;
    if (isFile || isDriveFile) {
        ev.preventDefault();
        draghover.value = true;
        switch (ev.dataTransfer.effectAllowed) {
            case "all":
            case "uninitialized":
            case "copy":
            case "copyLink":
            case "copyMove":
                ev.dataTransfer.dropEffect = "copy";
                break;
            case "linkMove":
            case "move":
                ev.dataTransfer.dropEffect = "move";
                break;
            default:
                ev.dataTransfer.dropEffect = "none";
                break;
        }
    }
}

function onDragenter(ev) {
    draghover.value = true;
}

function onDragleave(ev) {
    draghover.value = false;
}

function onDrop(ev): void {
    draghover.value = false;

    // ファイルだったら
    if (ev.dataTransfer.files.length > 0) {
        ev.preventDefault();
        for (const x of Array.from(ev.dataTransfer.files)) upload(x);
        return;
    }

    //#region ドライブのファイル
    const driveFile = ev.dataTransfer.getData(_DATA_TRANSFER_DRIVE_FILE_);
    if (driveFile != null && driveFile !== "") {
        const file = JSON.parse(driveFile);
        files.value.push(file);
        ev.preventDefault();
    }
    //#endregion
}

function saveDraft() {
    const draftData = JSON.parse(localStorage.getItem("drafts") || "{}");

    draftData[draftKey.value] = {
        updatedAt: new Date(),
        data: {
            text: text.value,
            useCw: useCw.value,
            cw: cw.value,
            visibility: visibility.value,
            localOnly: localOnly.value,
            files: files.value,
            poll: poll.value,
        },
    };

    localStorage.setItem("drafts", JSON.stringify(draftData));
}

function deleteDraft() {
    const draftData = JSON.parse(localStorage.getItem("drafts") || "{}");

    delete draftData[draftKey.value];

    localStorage.setItem("drafts", JSON.stringify(draftData));
}

async function post() {
    const processedText = preprocess(text.value);

    let postData = {
        editId: props.editId ? props.editId : undefined,
        text: processedText === "" ? undefined : processedText,
        fileIds:
            files.value.length > 0 ? files.value.map((f) => f.id) : undefined,
        replyId: props.reply ? props.reply.id : undefined,
        renoteId: props.renote
            ? props.renote.id
            : quoteId.value
            ? quoteId.value
            : undefined,
        poll: poll.value,
        cw: useCw.value ? cw.value || "" : undefined,
        localOnly: localOnly.value,
        visibility: visibility.value,
        visibleUserIds:
            visibility.value === "specified"
                ? visibleUsers.value.map((u) => u.id)
                : undefined,
    };

    if (withHashtags.value && hashtags.value && hashtags.value.trim() !== "") {
        const hashtags_ = hashtags.value
            .trim()
            .split(" ")
            .map((x) => (x.startsWith("#") ? x : "#" + x))
            .join(" ");
        postData.text = postData.text
            ? `${postData.text} ${hashtags_}`
            : hashtags_;
    }

    let token = undefined;

    if (postAccount.value) {
        const storedAccounts = await getAccounts();
        token = storedAccounts.find(
            (x) => x.id === postAccount.value.id
        )?.token;
    }

    posting.value = true;
    os.api(postData.editId ? "notes/edit" : "notes/create", postData, token)
        .then(() => {
            clear();
            nextTick(() => {
                deleteDraft();
                emit("posted");
                if (postData.text && postData.text !== "") {
                    const hashtags_ = mfm
                        .parse(postData.text)
                        .filter((x) => x.type === "hashtag")
                        .map((x) => x.props.hashtag);
                    const history = JSON.parse(
                        localStorage.getItem("hashtags") || "[]"
                    ) as string[];
                    localStorage.setItem(
                        "hashtags",
                        JSON.stringify(unique(hashtags_.concat(history)))
                    );
                }
                posting.value = false;
                postAccount.value = null;
            });
        })
        .catch((err) => {
            posting.value = false;
            os.alert({
                type: "error",
                text: err.message + "\n" + (err as any).id,
            });
        });
}

function cancel() {
    emit("cancel");
}

function insertMention() {
    os.selectUser().then((user) => {
        insertTextAtCursor(textareaEl.value, "@" + Acct.toString(user) + " ");
    });
}

async function insertEmoji(ev: MouseEvent) {
    os.openEmojiPicker(ev.currentTarget ?? ev.target, {}, textareaEl.value);
}

async function openCheatSheet(ev: MouseEvent) {
    os.popup(XCheatSheet, {}, {}, "closed");
}

let postAccount = ref<misskey.entities.UserDetailed | null>(null);

function openAccountMenu(ev: MouseEvent) {
    openAccountMenu_(
        {
            withExtraOperation: false,
            includeCurrentAccount: true,
            active: postAccount.value != null ? postAccount.value.id : $i.id,
            onChoose: (account) => {
                if (account.id === $i.id) {
                    postAccount.value = null;
                } else {
                    postAccount.value = account;
                }
            },
        },
        ev
    );
}

onMounted(() => {
    if (props.autofocus) {
        focus();

        nextTick(() => {
            focus();
        });
    }

    // TODO: detach when unmount
    autocomplete.value = [
        new Autocomplete(textareaEl.value!, text),
        new Autocomplete(cwInputEl.value!, cw),
        new Autocomplete(hashtagsInputEl.value!, hashtags),
    ];

    nextTick(() => {
        // 書きかけの投稿を復元
        if (!props.instant && !props.mention && !props.specified) {
            const draft = JSON.parse(localStorage.getItem("drafts") || "{}")[
                draftKey.value
            ];
            if (draft) {
                text.value = draft.data.text;
                useCw.value = draft.data.useCw;
                cw.value = draft.data.cw;
                visibility.value = draft.data.visibility;
                localOnly.value = draft.data.localOnly;
                files.value = (draft.data.files || []).filter(
                    (draftFile) => draftFile
                );
                if (draft.data.poll) {
                    poll.value = draft.data.poll;
                }
            }
        }

        // 削除して編集
        if (props.initialNote) {
            const init = props.initialNote;
            text.value = init.text ? init.text : "";

            files.value = init.attachments ?? [];
            cw.value = init.cw;
            useCw.value = init.cw != null;
            if (init.poll) {
                poll.value = {
                    choices: init.poll.options.map((x) => x.title),
                    multiple: init.poll.multiple_choice,
                    expiresAt: init.poll.expires_at,
                    // TODO(Natty)
                    expiredAfter: null,
                };
            }
            visibility.value = magLegacyVisibility(init.visibility);
            localOnly.value = init.local_only ?? false;
            quoteId.value = init.renoted_note ? init.renoted_note.id : null;
        }

        nextTick(() => watchForDraft());
    });
});

onBeforeUnmount(() => {
    autocomplete.value?.forEach((a) => a.detach());
});
</script>

<style lang="scss" scoped>
.gafaadew {
    position: relative;

    &.modal {
        width: 100%;
        max-width: 520px;
    }

    > header {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;

        > .left {
            display: flex;
            align-items: center;
            min-width: 0;
            flex: 1;

            > .cancel {
                flex: 0;
                font-size: 20px;
                height: 100%;
                aspect-ratio: 1 / 1;
                min-height: 56px;
            }

            > .account {
                flex: 0;
                box-sizing: content-box;
                height: 34px;
                aspect-ratio: 1;

                > .avatar {
                    width: 28px;
                    height: 28px;
                    margin: auto;
                }
            }

            > .form-username {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 6px;
                opacity: 0.7;
            }
        }

        > .right {
            display: flex;
            flex-direction: row;
            gap: 4px;
            padding: 12px;
            align-items: center;
            justify-content: flex-end;

            > .text-count {
                opacity: 0.7;
                line-height: 34px;
                margin: 0 6px;
            }

            > .local-only {
                width: 28px;
                height: 28px;
                border-left: 1px solid var(--fg);
                border-top: 1px solid var(--fg);
                border-bottom: 1px solid var(--fg);
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                opacity: 0.7;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 0 4px;
                box-sizing: border-box;

                & + .visibility {
                    width: 28px;
                    height: 28px;
                    border-right: 1px solid var(--fg);
                    border-top: 1px solid var(--fg);
                    border-bottom: 1px solid var(--fg);
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    margin: 0 4px 0 0;
                }
            }

            > .visibility {
                height: 34px;
                width: 34px;
                line-height: 16px;
            }

            > .preview {
                display: inline-block;
                padding: 0;
                margin: 0 6px 0 0;
                font-size: 16px;
                width: 34px;
                height: 34px;
                border-radius: 6px;

                &:hover {
                    background: var(--X5);
                }

                &.active {
                    color: var(--accent);
                }
            }

            > .submit {
                display: inline-flex;
                align-items: center;
                margin: 0;
                padding: 0 12px;
                line-height: 34px;
                font-weight: bold;
                border-radius: 4px;
                font-size: 0.9em;

                &:disabled {
                    opacity: 0.7;
                }

                > i {
                    margin-left: 6px;
                }
            }
        }
    }

    > .form {
        > .preview {
            padding: 16px;
        }

        > .with-quote {
            display: flex;
            align-items: center;
            gap: 0.4em;
            margin-inline: 24px;
            margin-bottom: 12px;
            color: var(--accent);

            > button {
                display: flex;
                padding: 0;
                color: var(--accentAlpha04);

                &:hover {
                    color: var(--accentAlpha06);
                }

                &:active {
                    color: var(--accentDarken30);
                }
            }
        }

        > .to-specified {
            padding: 6px 24px;
            margin-bottom: 8px;
            overflow: auto;
            line-height: 2rem;

            > .visibleUsers {
                display: inline;
                top: -1px;
                font-size: 14px;

                > button {
                    padding: 2px;
                    border-radius: 8px;

                    > i {
                        transform: translateX(2px);
                    }
                }

                > span {
                    margin: 0.3rem;
                    padding: 4px 0 4px 4px;
                    border-radius: 999px;
                    background: var(--X3);

                    > button {
                        padding: 4px 8px;
                    }
                }
            }
        }

        > .hasNotSpecifiedMentions {
            margin: 0 20px 16px 20px;
        }

        > .cw,
        > .hashtags,
        > .text {
            display: block;
            box-sizing: border-box;
            padding: 0 24px;
            margin: 0;
            width: 100%;
            font-size: 1.05em;
            border: none;
            border-radius: 0;
            background: transparent;
            color: var(--fg);
            font-family: inherit;

            &:focus {
                outline: none;
            }

            &:disabled {
                opacity: 0.5;
            }
        }

        > .cw {
            z-index: 1;
            padding-bottom: 8px;
            border-bottom: solid 0.5px var(--divider);
        }

        > .hashtags {
            z-index: 1;
            padding-top: 8px;
            padding-bottom: 8px;
            border-top: solid 0.5px var(--divider);
        }

        > .text {
            max-width: 100%;
            min-width: 100%;
            min-height: 90px;

            &.withCw {
                padding-top: 8px;
            }
        }

        > footer {
            padding: 0 16px 16px 16px;

            > button {
                display: inline-block;
                padding: 0;
                margin: 0;
                font-size: 16px;
                width: 48px;
                height: 48px;
                border-radius: 6px;

                &:hover {
                    background: var(--X5);
                }

                &.active {
                    color: var(--accent);
                }
            }

            > .right {
                float: right;
            }
        }
    }

    &.max-width_500px {
        > header {
            > .left {
                min-width: auto;

                > .form-username {
                    display: none;
                }
            }

            > .right {
                flex-grow: 1;
            }
        }

        > .form {
            > .to-specified {
                padding: 6px 16px;
            }

            > .cw,
            > .hashtags,
            > .text {
                padding: 0 16px;
            }

            > .text {
                min-height: 80px;
            }

            > footer {
                padding: 0 8px 8px 8px;
            }
        }
    }

    &.max-width_310px {
        > .form {
            > footer {
                > button {
                    font-size: 14px;
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }
}
</style>
