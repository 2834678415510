<template>
    <button v-if="hide" class="qjewsnkg" @click="hide = false">
        <ImgWithBlurhash
            :hash="image.blurhash ?? undefined"
            :title="image.comment"
            :alt="image.comment ?? ''"
        />
        <div class="text">
            <div class="wrapper">
                <b style="display: block"
                    ><i class="ph-warning ph-bold ph-lg"></i>
                    {{ i18n.ts.sensitive }}</b
                >
                <span style="display: block">{{ i18n.ts.clickToShow }}</span>
            </div>
        </div>
    </button>
    <div v-else class="gqnyydlz">
        <a :href="image.url" :title="image.name">
            <ImgWithBlurhash
                :hash="image.blurhash ?? undefined"
                :src="url"
                :alt="image.comment ?? ''"
                :type="magTransProperty(image, 'mime_type', 'type')"
                :title="image.comment"
                :cover="false"
            />
            <div
                v-if="
                    magTransProperty(image, 'mime_type', 'type') === 'image/gif'
                "
                class="gif"
            >
                GIF
            </div>
        </a>
        <div class="_image_controls">
            <button
                v-if="!image.comment"
                v-tooltip="i18n.ts.noAltText"
                class="_button _image_control_btn _image_control_warn"
                @click="noAltTextPopup"
            >
                <i class="ph-file-x ph-bold ph-lg"></i>
            </button>
            <button
                v-if="image.comment"
                v-tooltip="i18n.ts.altText"
                class="_button _image_control_btn"
                @click="altTextPopup"
            >
                <i class="ph-file-text ph-bold ph-lg"></i>
            </button>
            <button
                v-tooltip="i18n.ts.hide"
                class="_button _image_control_btn"
                @click="hide = true"
            >
                <i class="ph-eye-slash ph-bold ph-lg"></i>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import type * as misskey from "calckey-js";
import * as os from "@/os";
import { getStaticImageUrl } from "@/scripts/get-static-image-url";
import ImgWithBlurhash from "@/components/MkImgWithBlurhash.vue";
import { defaultStore } from "@/store";
import { i18n } from "@/i18n";
import { packed } from "magnetar-common";
import { magTransProperty } from "@/scripts-mag/mag-util";

const props = defineProps<{
    image: packed.PackDriveFileBase | misskey.entities.DriveFile;
    raw?: boolean;
}>();

let hide = ref(true);

function noAltTextPopup(event: MouseEvent) {
    os.alert({
        type: "warning",
        title: i18n.ts.noAltText,
        text: i18n.ts.noAltTextDescription,
    });

    event.stopPropagation();
}

function altTextPopup(event: MouseEvent) {
    os.alert({
        type: "info",
        title: i18n.ts.altText,
        text: props.image.comment,
    });

    event.stopPropagation();
}

const url =
    props.raw || defaultStore.state.loadRawImages
        ? props.image.url
        : defaultStore.state.disableShowingAnimatedImages
          ? getStaticImageUrl(
                magTransProperty(props.image, "thumbnail_url", "thumbnailUrl")!,
            )
          : magTransProperty(props.image, "thumbnail_url", "thumbnailUrl")!;

// Plugin:register_note_view_interruptor を使って書き換えられる可能性があるためwatchする
watch(
    () => props.image,
    () => {
        hide.value =
            defaultStore.state.nsfw === "force"
                ? true
                : magTransProperty(props.image, "sensitive", "isSensitive") &&
                  defaultStore.state.nsfw !== "ignore";
    },
    {
        deep: true,
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
.qjewsnkg {
    all: unset;
    position: relative;

    > .text {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.5);

        > .wrapper {
            display: table-cell;
            text-align: center;
            font-size: 0.8em;
            color: #fff;
        }
    }

    &:focus-visible {
        border: 2px solid var(--accent);
    }
}

.gqnyydlz {
    position: relative;
    background: var(--bg);

    > ._image_controls {
        display: flex;
        position: absolute;
        gap: 6px;
        top: 12px;
        right: 12px;

        > ._image_control_btn {
            display: block;
            border-radius: 6px;
            background-color: var(--accentedBg);
            -webkit-backdrop-filter: var(--blur, blur(15px));
            backdrop-filter: var(--blur, blur(15px));
            color: var(--accent);
            font-size: 0.8em;
            padding: 6px 8px;
            text-align: center;
            border: 2px solid transparent;

            > i {
                display: block;
            }
        }

        > ._image_control_warn {
            color: #f6b195;
            border: 2px solid #fa895c;
            box-sizing: border-box;
        }
    }

    > a {
        display: block;
        cursor: zoom-in;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        box-sizing: border-box;
        &:focus-visible {
            border: 2px solid var(--accent);
        }

        > .gif {
            background-color: var(--fg);
            border-radius: 6px;
            color: var(--accentLighten);
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            left: 12px;
            opacity: 0.5;
            padding: 0 6px;
            text-align: center;
            top: 12px;
            pointer-events: none;
        }
    }
}
</style>
