<template>
    <img
        v-if="isCustom"
        class="mk-emoji custom"
        :class="{ normal, noStyle }"
        :src="url"
        :alt="alt"
        :title="alt"
        decoding="async"
    />
    <img
        v-else-if="char && !useOsNativeEmojis"
        class="mk-emoji"
        :src="url"
        :alt="alt"
        :title="alt"
        decoding="async"
    />
    <span v-else-if="char && useOsNativeEmojis">{{ char }}</span>
    <span v-else-if="isRaw"><i class="ph-file-dashed ph-bold ph-lg"></i></span>
    <span v-else>{{ emoji }}</span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { getStaticImageUrl } from "@/scripts/get-static-image-url";
import { char2filePath } from "@/scripts/twemoji-base";
import { defaultStore } from "@/store";
import { types } from "magnetar-common";
import {
    magIsCustomEmoji,
    magIsUnicodeEmoji,
    magReactionToLegacy,
} from "@/scripts-mag/mag-util";

const props = defineProps<{
    emoji: types.Reaction;
    normal?: boolean;
    noStyle?: boolean;
    isReaction?: boolean;
}>();

const isCustom = computed(() => magIsCustomEmoji(props.emoji));

const isRaw = computed(
    () => !magIsCustomEmoji(props.emoji) && !magIsUnicodeEmoji(props.emoji),
);

const char = computed(() =>
    magIsUnicodeEmoji(props.emoji) ? (props.emoji as string) : null,
);
const useOsNativeEmojis = computed(
    () => defaultStore.state.useOsNativeEmojis && !props.isReaction,
);
const url = computed(() => {
    if (char.value) {
        return char2filePath(char.value);
    } else if (magIsCustomEmoji(props.emoji)) {
        return defaultStore.state.disableShowingAnimatedImages
            ? getStaticImageUrl((props.emoji as types.ReactionShortcode).url)
            : (props.emoji as types.ReactionShortcode).url;
    } else {
        return null;
    }
});
const alt = computed(() => magReactionToLegacy(props.emoji));
</script>

<style lang="scss" scoped>
.mk-emoji {
    height: 1.25em;
    vertical-align: -0.25em;

    &.custom {
        height: 2.5em;
        vertical-align: middle;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
        }

        &.normal {
            height: 1.25em;
            vertical-align: -0.25em;

            &:hover {
                transform: none;
            }
        }
    }

    &.noStyle {
        height: auto !important;
    }
}
</style>
