const address = new URL(location.href);
const siteName = (
    document.querySelector('meta[property="og:site_name"]') as HTMLMetaElement
)?.content;

export const host = _HOST || address.host;
export const hostname = address.hostname;
export const url = _REMOTE_URL || address.origin;
export const apiUrl = `${url}/api`;
export const magStreamingUrl = `${url}/mag/v1`;
export const feApiUrl = `${url}/fe-api`;
export const wsUrl = `${url
    .replace("http://", "ws://")
    .replace("https://", "wss://")}/streaming`;
export const lang = localStorage.getItem("lang");
export const langs = _LANGS_;
export const locale = JSON.parse(localStorage.getItem("locale"));
export const version = _VERSION_;
export const instanceName = siteName === "Magnetar" ? "" + host : siteName;
export const ui = localStorage.getItem("ui");
export const debug = localStorage.getItem("debug") === "true";
