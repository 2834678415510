<template>
    <MagAvatar v-if="userResolved" :user="userResolved" />
    <span
        class="avatar-placeholder"
        :class="{ square: $store.state.squareAvatars }"
        v-else
    >
    </span>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { userIsMag } from "@/scripts-mag/mag-util";
import { endpoints, packed } from "magnetar-common";
import type * as Misskey from "calckey-js";
import * as os from "@/os";

const props = withDefaults(
    defineProps<{
        user: packed.PackUserBase | Misskey.entities.User;
        target?: string | null;
        disableLink?: boolean;
        disablePreview?: boolean;
    }>(),
    {
        target: null,
        disableLink: false,
        disablePreview: false,
    }
);

const userResolved = ref<packed.PackUserBase | null>(null);

watch(
    () => props.user,
    async (val) => {
        if (userIsMag(val)) {
            userResolved.value = val;
        }

        userResolved.value = await os.magApi(
            endpoints.GetUserById,
            {},
            { user_id: val.id }
        );
    },
    { immediate: true }
);
</script>
<style lang="scss" scoped>
.avatar-placeholder {
    display: inline-block;
    background-color: var(--buttonBg);
    opacity: 0.6;

    border-radius: 100%;

    &.square {
        border-radius: 20%;

        > .inner {
            border-radius: 20%;
        }
    }
}
</style>
