<template>
    <MkLink class="mention" :url="url" @click.stop>
        <span class="icon">matrix /</span>
        <span class="main">
            <span class="username">@{{ username }}</span>
            <span class="host">:{{ toUnicode(host) }}</span>
        </span>
    </MkLink>
</template>

<script lang="ts" setup>
import { toUnicode } from "punycode";
import MkLink from "@/components/MagLink.vue";
import { computed } from "vue";

const props = defineProps<{
    username: string;
    host: string;
}>();

const url = computed(
    () => `https://matrix.to/#/@${props.username}:${props.host}`
);
</script>

<style lang="scss" scoped>
.mention {
    position: relative;
    display: inline-block;
    padding: 2px 8px 2px 2px;
    margin-block: 2px;
    border-radius: 999px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--mention);
    isolation: isolate;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        background: var(--mention);
        opacity: 0.1;
        z-index: -1;
    }

    > .icon {
        margin: 0 0.2em 0 0.35em;
        border-radius: 100%;
        opacity: 0.7;
    }

    > .main > .host {
        opacity: 0.8;
    }
}
</style>
