import { markRaw } from "vue";
import { locale } from "@/config";
import { I18n } from "@/scripts/i18n";

// HACK: Type checking the locale
type LocaleMap = { [property: string]: LocaleMap } & string;

export const i18n = markRaw(new I18n<LocaleMap>(locale));

// このファイルに書きたくないけどここに書かないと何故かVeturが認識しない
declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $t: (typeof i18n)["t"];
        $ts: (typeof i18n)["locale"];
    }
}
