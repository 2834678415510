"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    parse: function() {
        return parse;
    },
    toString: function() {
        return toString;
    }
});
function parse(acct) {
    if (acct.startsWith("@")) acct = acct.substr(1);
    const split = acct.split("@", 2);
    return {
        username: split[0],
        host: split[1] || null
    };
}
function toString(acct) {
    return acct.host == null ? acct.username : `${acct.username}@${acct.host}`;
}
